import React from 'react'
import styled from 'styled-components'

import DoggoBackground from '../shared/DoggoBackground'
import { LargeP, ExternalLink } from '../shared/text'
import { PrimaryButton } from '../shared/buttons'
import { colors, fonts } from '../../styles/variables'
import { media } from '../../styles/util'

const Section = styled.section`
  width: 100%;
  background-color: ${colors.orange};
  ${media.tablet`
    padding: 10px 30px 0 30px;
  `}
`

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  ${media.desktop`
    max-width: 1200px;
  `};
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  place-items: center;
  ${media.mobile`
    padding: 70px 0 70px 0;
  `}
  ${media.tablet`
    padding: 50px 0 80px 0;
  `}
  ${media.desktop`
    padding: 150px 0 230px 0;
  `}
`

const Card = styled.div`
  background-color: ${colors.white};
  border-radius: 25px;
  margin: 0 auto;
  display: grid;
  place-items: center;
  width: 90%;
  opacity: 0.9;
  ${media.mobile`
    padding: 40px 30px;
    margin-bottom: 30px;
  `}
  ${media.tablet`
    max-width: 700px;
    padding: 40px 80px;
    margin-bottom: 0;
  `}
  ${media.desktop`
    max-width: 884px;
    padding: 40px 96px;
  `}
`

const Copy = styled(LargeP)`
  &:last-of-type {
    ${media.mobile`
      margin-bottom: 20px;
    `}
    ${media.tablet`
      margin-bottom: 30px;
    `}
    ${media.desktop`
      margin-bottom: 40px;
    `}
  }
`

const Email = styled.span`
  font-family: ${fonts.larsseit.medium};
  color: ${colors.orange};
  &:hover {
    text-decoration: underline;
  }
`

const Partnerships = () => {
  const PartnerCard = () => (
    <Card>
      <Copy>
        Partnership opportunity? Reach out to{' '}
        <ExternalLink to="mailto:partner@petfinn.com">
          <Email>partner@petfinn.com</Email>
        </ExternalLink>
        , we’d love to hear from you!
      </Copy>
      <br />
      <Copy>
        Anything else? Hit us up at{' '}
        <ExternalLink to="mailto:hello@petfinn.com">
          <Email>hello@petfinn.com</Email>
        </ExternalLink>{' '}
        and we’ll be happy to help!
      </Copy>
      <ExternalLink to="mailto:hello@petfinn.com">
        <PrimaryButton copy="Contact Us" arrow />
      </ExternalLink>
    </Card>
  )

  return (
    <Section>
      <Wrapper>
        <DoggoBackground>
          <Container>
            <PartnerCard />
          </Container>
        </DoggoBackground>
      </Wrapper>
    </Section>
  )
}
export default Partnerships
