import React from 'react'
import { PageProps } from 'gatsby'

import Layout from 'src/layouts'
import FAQ from 'src/components/FAQ'
import SEO from 'src/components/shared/SEO'

const SEO_CONFIG = {
  title: 'Frequently Asked Questions (FAQs) | Finn',
  description:
    'Read all the frequently asked questions and you will find the answer you are looking for. Questions like, why should I give my dog supplements, and more.',
  keywords: ['Frequently Asked Questions']
}

const FAQPage: React.FC<PageProps> = ({ location }) => {
  const { title, description, keywords } = SEO_CONFIG

  return (
    <>
      <SEO title={title} description={description} keywords={keywords} location={location} />

      <Layout location={location}>
        <FAQ />
      </Layout>
    </>
  )
}

export default FAQPage
