import React from 'react'

import Header from './Header'
import Faqs from './Faqs'
import Partnerships from './Partnerships'

import FaqData from './data'

export default React.memo(() => (
  <>
    <Header faqData={FaqData} />
    <Faqs faqData={FaqData} />
    <Partnerships />
  </>
))
