import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import DropHeader from '../shared/DropHeader'
import { Headline } from '../shared/text'
import { ActionButton } from '../shared/buttons'
import { colors } from '../../styles/variables'
import { media } from '../../styles/util'

const Section = styled.section`
  position: relative;
  width: 100%;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.mobile`
    margin-top: 0px;
  `}
  ${media.tablet`
    margin-top: 40px;
  `}
  ${media.desktop`
    margin-top: 0px;
  `}
`

const ImageWrapper = styled.div`
  width: 100%;
  ${media.mobile`
    margin: 0 0 40px 0;
    height: 300px;
  `}
  ${media.tablet`
    height: 330px;
  `}
  ${media.desktop`
    margin: 0 0 47px 0;
    height: 430px;
  `}
`

const ActionRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  ${media.mobile`
    max-width: 100%;
    margin: 40px auto 40px auto;
  `}
  ${media.tablet`
    max-width: 80%;
    margin: 40px auto 80px auto;
  `}
  ${media.desktop`
    max-width: 1265px;
    margin: 60px auto 80px auto;
  `}
`

interface QuestionProps {
  question: string
  answer: string
}

interface DataProps {
  id: string
  title: string
  questionList: QuestionProps[]
}

const query = graphql`
  query {
    hero: file(relativePath: { regex: "/faq/hero.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 1400)
      }
    }
  }
`

const Header = ({ faqData }: { faqData: DataProps[] }) => {
  const images = useStaticQuery(query)

  return (
    <Section>
      <DropHeader title="Got Questions?" isPageHeader />
      <ImageWrapper>
        <GatsbyImage
          image={getImage(images.hero)}
          alt="Woman and her dog in a park"
          style={{ height: '100%', width: '100%' }}
          imgStyle={{
            objectFit: 'cover',
            backgroundPosition: 'bottom',
            backgroundRepeat: 'no-repeat'
          }}
        />
      </ImageWrapper>
      <Headline>We've Got Answers</Headline>
      <ActionRow>
        {faqData.map((section: DataProps) => (
          <ActionButton
            copy={section.title}
            onClick={() => navigate(`/faq#${section.id}`)}
            key={section.title}
          />
        ))}
      </ActionRow>
    </Section>
  )
}

export default Header
