import React, { useState } from 'react'
import styled from 'styled-components'

import FaqGroup from '../shared/FaqGroup'
import { H6, LargeP, SmallP, ExternalLink } from '../shared/text'
import { NavButton } from '../shared/buttons'
import { colors, fonts } from '../../styles/variables'
import { media } from '../../styles/util'

import HalfWein from '../../assets/img/dog-icons/dachshund-left-navy.svg'

const Section = styled.section`
  background-color: ${colors.white};
  width: 100%;
  ${media.mobile`
    padding: 0 10px 40px 10px;
  `}
  ${media.desktop`
    padding: 0 40px 100px 40px;
  `}
`

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  display: grid;
  align-items: end;
  ${media.mobile`
    grid-template-columns: 1fr;
    padding: 0 10px;
  `}
  ${media.tablet`
    grid-template-columns: 1fr 150px;
    padding: 0 40px;
  `}
  ${media.desktop`
    grid-template-columns: 1fr 290px;
    grid-gap: 20px;
    max-width: 1205px;
  `}
`

const ViewAllWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  ${media.mobile`
    padding-bottom: 60px;
  `}
  ${media.tablet`
    padding-bottom: 60px;
  `}
`

const Divider = styled.div`
  width: 100%;
  margin: 0 auto;
  height: 1px;
  background-color: ${colors.lightText};
  ${media.mobile`
    max-width: 95%;
    margin: 0px auto 40px auto;
  `}
  ${media.tablet`
    max-width: 90%;
    margin: 40px auto 80px auto;
  `}
  ${media.desktop`
    max-width: 100%;
    margin: 40px 0 80px 0;
  `}
`

const QuestionsCard = styled.div`
  width: 100%;
  background-color: ${colors.pink};
  border-radius: 25px;
  padding: 30px 40px 80px 40px;
  position: relative;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  ${media.mobile`
    display: none;
  `}
  ${media.desktop`
    display: flex;
  `}
`

const QuestionHeader = styled(LargeP)`
  font-family: ${fonts.larsseit.regular};
  border-bottom: 1px solid ${colors.navy};
  padding-bottom: 12px;
  margin-bottom: 12px;
`

const ContactButtonWrapper = styled.div`
  width: 100%;
  ${media.mobile`
    margin: 10px 0 0 12px;
    margin-left: 12px;
  `}
  ${media.desktop`
    margin: 16px 0 0 20px;
  `}
`

const WeinIcon = styled.img`
  position: absolute;
  bottom: 6px;
  right: 0;
  height: 61px;
  transform: translate(50%, 0);
`

const Bold = styled.span`
  font-family: ${fonts.larsseit.medium};
`

const StillHaveQuestions = () => (
  <QuestionsCard>
    <QuestionHeader>Still have questions?</QuestionHeader>
    <SmallP>
      Send us an email at{' '}
      <ExternalLink to="mailto:hello@petfinn.com">
        <Bold>hello@petfinn.com</Bold>
      </ExternalLink>
    </SmallP>
    <ContactButtonWrapper>
      <ExternalLink to="mailto:hello@petfinn.com">
        <NavButton copy="Contact Us ➔" dark />
      </ExternalLink>
    </ContactButtonWrapper>
    <WeinIcon src={HalfWein} alt="Dachshund icon" />
  </QuestionsCard>
)

interface QuestionProps {
  question: string
  answer: string
  answerToHtml: boolean
}

interface DataProps {
  id: string
  title: string
  questionList: QuestionProps[]
}

const Faqs = ({ faqData }: { faqData: DataProps[] }) => {
  return (
    <Section>
      {faqData.map((section: DataProps) => {
        const initialVisibleQuestions = 3

        const [expanded, setExpanded] = useState(section.id === 'why-finn')
        const adjQuestionList = expanded ? section.questionList : section.questionList.slice(0, initialVisibleQuestions)
        return (
          <div id={section.id} key={section.id}>
            {section.id !== 'why-finn' && <Divider />}
            <Container>
              <div>
                <H6>{section.title}</H6>
                <FaqGroup questionList={adjQuestionList} />
              </div>
              {section.questionList.length > initialVisibleQuestions && !expanded && (
                <ViewAllWrapper>
                  <NavButton copy="View All" onClick={() => setExpanded(true)} />
                </ViewAllWrapper>
              )}
              {section.id === 'packaging-and-storage' && <StillHaveQuestions />}
            </Container>
          </div>
        )
      })}
    </Section>
  )
}

export default Faqs
