const FaqData = [
  {
    title: 'Why Finn?',
    id: 'why-finn',
    questionList: [
      {
        question: 'Why should I give my dog supplements?',
        answer:
          'We are focused on improving your dog’s daily health and lifestyle by providing top-quality food and health supplements to enrich their usual diet. That’s why we have formulated our soft chews with only the best ingredients that are clinically proven to support your dog’s health and overall well-being. Because when your pup is healthy, everybody’s happy.'
      },
      {
        question: 'Doesn’t my dog get all the necessary nutrients in their food?',
        answer:
          'Simply put, it depends on your dogs diet, breed, age, lifestyle, and more! Food supplements may provide additional nutrients that your dog isn’t getting from their normal diet. And health supplements help keep your dog in peak condition.'
      },
      {
       question: 'What are concerns that would require me to give my dog supplements?',
       answer: 'Our variety of food and health supplements should be used on a health maintenance basis for general health improvement and can help support healthy inflammation responses, skin, immune function, and joint health. Giving your pup food and health supplements on a regular basis can help maintain nearly all aspects of health. That’s why we partner with top-notch vets and animal health experts to make sure our soft chews are always up to snuff.'
      },
      {
        question: 'Can I give these to my puppy? How old should my dog be?',
        answer:
          'Our soft chews are safe to be consumed by pups of all ages! While some conditions take time to develop, preventative health supplements or food supplements are the best way to support your dog’s long-term health and safety. We always recommend discussing with your veterinarian if you have any concerns.'
      }
    ]
  },
  {
    title: 'Safety & Quality',
    id: 'safety-and-quality',
    questionList: [
      {
        question: 'Who formulates Finn’s products, and what are their credentials?',
        answer:
          'We manufacture our soft chews in a US-based, current Good Manufacturing Practice (cGMP) compliant facility that is Safe Quality Foods (SQF) certified and certified by the National Animal Supplements Council (NASC). Our team works hard to find the best manufacturing partners, source the highest quality ingredients, and partner with suppliers who live up to our standards of testing, safety, and quality.'
      },
      {
        question: 'What food safety inspections or qualifications are undertaken?',
        answer:
          'Every batch of our soft chews is lab-tested before leaving the facility, and has a unique code that tracks ingredient supplier, manufacturing shift, testing, and processing. We’ll never compromise or cut corners, because your pet’s well-being is not worth the risk.'
      },
      {
        question: 'Where can I find detailed ingredient information and directions for use?',
        answer:
          'Please refer to the individual product pages on our website and our labels to review detailed ingredient information and directions for use. If you have any questions, we’re always here to help - just reach out to hello@petfinn.com.'
      },
      {
        question: 'Do your products contain any ingredients that will be bad for my pet?',
        answer:
          'We have partnered extensively with vets, animal health experts, and our manufacturing partners to create products with ingredients of the highest quality. We made room for more of the active ingredients by eliminating cheap fillers and harmful additives. Our soft chews do not contain any cellulose powder, palm oil, corn, soy, wheat, artificial colors, or artificial flavors. They are cooked through cold-press extrusion, using very low temperatures to preserve maximum potency for your pooch!'
      }
    ]
  },
  {
    title: 'Efficacy & Administration',
    id: 'efficacy-and-administration',
    questionList: [
      {
        question: 'How many servings should I give my dog?',
        answer: 'Every pup is unique. We recommend a daily suggested serving based on your dog’s weight.',
        bullets: ['1 - 25lbs: 1 chew per day', '26 - 50lbs: 2 chews per day', '51 - 75lbs: 3 chews per day', 'Over 75lbs: 4 chews per day']
      },
      {
        question: 'How can I get my dog to eat these daily?',
        answer:
          'We’ve formulated all of our health and food supplements as soft chews, so they’re easy-to-give and yummy-to-eat. With a focus on taste and palatability, we’re certain your pup will be panting for more!'
      },
      {
        question: 'Can I give my dog Finn soft chews when they are taking medications?',
        answer:
          'Our products are meant to be paired with your dog’s usual diet in order to help your pup live their best, healthiest life. We always recommend discussing with your veterinarian if you have any concerns, especially if your pup is taking medication(s) or has a pre-existing health condition.'
      },
      {
        question: 'How long will it take for me to see the positive effects in my pup?',
        answer: 'Every dog is unique but with daily use, you should start to see results within around 3 weeks.'
      },
      {
        question: 'Can I use these soft chews as treats',
        answer:
          'Yes, you can use our soft chews as healthy treats! But be mindful of how many your pup takes in a day – our soft chews are still food or health supplements, and too many may impact their nutrient balance. We recommend sticking to the daily serving size suggested based on your dog’s size.'
      }
    ]
  },
  {
    title: 'Packaging & Storage',
    id: 'packaging-and-storage',
    questionList: [
      {
        question: 'Is your packaging safe for my dog?',
        answer:
          'Yes! Our tins are BPA-free and FDA-approved, so you can rest assured that the chews inside are safe and secure. Store the tins in a cool, dry place to maintain freshness for up to 2 years.'
      },
      {
        question: 'Is your packaging recyclable?',
        answer:
          'At Finn, sustainability and wellness are at the heart of what we do. We believe in good health for humans, animals, and the planet. That’s why we package our soft chews in recyclable sources. Our tins are made with 100% recycled steel and can be recycled in perpetuity. Our cardboard packages can also be recycled to further minimize our environmental footprint.'
      }
    ]
  },
  {
    title: 'Shipping & Returns',
    id: 'shipping-and-returns',
    questionList: [
      {
        question: 'Where do you ship to?',
        answer:
          'We ship to all 50 states, and offer free shipping on subscription orders and orders greater than $35. Once processed, most orders will generally take 1-2 days to ship and an additional 3-7 days to deliver depending on the shipment destination. We do not currently ship to PO boxes, APO/FPOs, or international addresses.'
      },
      {
        question: 'How can I update my shipping address?',
        answer:
          'You can always update your shipping address or any other account details through your Account page. If you have any questions, we’re always here to help at hello@petfinn.com.'
      },
      {
        question: 'How can I return my product? Can I get a refund?',
        answer:
          'We’re certain your pup will love our soft chews, and more importantly, feel great after proper consumption. If you’re unhappy with our product, just email hello@petfinn.com and we’ll get you sorted out with a refund.'
      }
    ]
  },
  {
    title: 'Account Management',
    id: 'account-management',
    questionList: [
      {
        question: 'How does the subscription offering work?',
        answer:
          'Every pup is unique, so there’s no ‘one size fits all’ when it comes to delivery cadence. We strive to offer a variety of subscription plans so your four-legged friend always has the support they deserve. Depending on your pup’s weight and serving size, subscription frequency may vary from just a few weeks to as long as 90 days between shipments.'
      },
      {
        question: 'How can I update, change or cancel my subscription preferences?',
        answer:
          'You can update any details including product selection and subscription frequency through your Account page. You can pause or cancel your subscription at any time. While we hope you remain a Finn supporter forever, we understand that things change. If you need any help, just reach out to hello@petfinn.com.'
      },
      {
        question: 'Do I need a subscription?',
        answer:
          'Nope – if you’re not quite ready for a subscription commitment, all Finn products can be purchased a la carte. However, a subscription is the easiest way to make sure you’re always stocked up on the necessary nutrients!'
      },
      {
        question: 'What payment methods do you accept?',
        answer: 'We accept all major credit cards (Visa, Mastercard, American Express, Discover).'
      },
      {
        question: 'How can I check the status or track my order?',
        answer:
          'You will receive an email with all tracking details once your order has been shipped. You can always check previous orders through your Account page.'
      },
      {
        question: "Your site says I don't have an account, what do I do?",
        answer: `If you created an account after your purchase, make sure you are logging in with the same email you used in checkout to manage any subscriptions. Unfortunately, having a subscription does not automatically create an account. If you haven't previously created an account, you can create one <a href="https://shop.petfinn.com/account/register" style="text-decoration: underline;">here</a>`,
        answerToHtml: true
      }
    ]
  }
]

export default FaqData
